<template>
    <div id="sens-sms-page">
        <v-container>
            <Header :text="$t('sens_sms_page.sens_sms')" :title="$t('dashboard.send_sms_config')" />

            <device-input
                ref="DeviceInput"
                :type="type_pnes"
                class="mb-4"
                label="PNES"
                v-on:getDeviceAsset="getPnesAssetInformation"
                v-on:getDeviceDetails="getDevicePnesDetails"
                v-on:reset="resetForm"
                v-on:resetLocal="resetForm"
            />

            <v-expand-transition>
                <div v-if="pnes_device_number !== ''" class="d-flex flex-column gap-4">
                    <div v-if="asset.asset">
                        <asset-summary-component :asset="asset.asset" :module="asset_module" />
                    </div>

                    <div>
                        <p class="mb-2 font-18 font-weight-bold">
                            {{ $t('sens_sms_page.configure_call_schedule') }}
                        </p>

                        <v-select
                            v-model="weekly_preset_selected"
                            :items="weekly_preset"
                            :label="$t('sens_sms_page.preset')"
                            :menu-props="{ bottom: true, offsetY: true }"
                            class="white-input"
                            filled
                            item-text="label"
                            item-value="key"
                            return-object
                            @change="handleWeeklyPresetChange"
                        />

                        <div class="overflow-x-auto">
                            <table class="weekly-table">
                                <tr v-for="day in 8">
                                    <template v-for="hour in 25">
                                        <template v-if="day === 1 && hour > 1">
                                            <th class="px-2 py-1">
                                                <p class="mb-0">{{ hour - 2 }}</p>
                                            </th>
                                        </template>
                                        <template v-else-if="hour === 1 && day > 1">
                                            <th class="px-2 py-1 text-start">
                                                <p class="mb-0">{{ days[day - 2] }}</p>
                                            </th>
                                        </template>
                                        <template v-else-if="hour > 1 && day > 1">
                                            <td
                                                :class="`${isActivateWeeklyByDayAndHour(day - 2, hour - 2) ? 'selected' : ''}`"
                                                @click="activateWeeklyByDayAndHour(day - 2, hour - 2)"
                                            />
                                        </template>
                                        <template v-else>
                                            <th />
                                        </template>
                                    </template>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <hr />

                    <div class="mb-4">
                        <p class="font-18 mb-0 font-weight-bold">{{ $t('sens_sms_page.configuring_list_repeated_modules') }} :</p>
                        <device-input
                            :label="$t('sens_sms_page.add_module')"
                            :type="type_pnes_module"
                            class="mb-2"
                            v-on:getDeviceDetails="getModuleDeviceInformation"
                        />

                        <div>
                            <p class="font-14 font-weight-bold mb-2">{{ $t('sens_sms_page.list_modules') }}</p>
                            <template v-if="modules.length > 0">
                                <div class="d-flex flex-column gap-4">
                                    <v-chip
                                        v-for="(module, index) in modules"
                                        :key="module.device_number"
                                        close
                                        color="success"
                                        style="width: fit-content"
                                        @click:close="
                                            () => {
                                                modules.splice(index, 1)
                                            }
                                        "
                                    >
                                        {{ module.device_number }}
                                    </v-chip>
                                </div>
                            </template>
                            <template v-else>
                                <p class="font-14 font-weight-bold mb-2">{{ $t('sens_sms_page.no_modules_added') }}</p>
                            </template>
                        </div>
                    </div>

                    <v-btn :loading="encoding_sms_to_pnes" class="validation-button" @click="openValidateSendSmsToPnesDialog">
                        {{ $t('sens_sms_page.send_configuration') }}
                    </v-btn>
                </div>
            </v-expand-transition>
        </v-container>

        <v-dialog v-model="is_validate_send_sms_to_pnes_dialog_open" max-width="600">
            <v-card>
                <v-card-title>
                    {{ $t('sens_sms_page.confirm_configuration_upload') }}
                </v-card-title>
                <v-card-text>
                    {{
                        $t('sens_sms_page.will_send_payload_to_device', {
                            device_number: pnes_device_number,
                            phone_number: this.pnes_device.phone_number,
                        })
                    }}
                    <p class="font-weight-bold">{{ payload }}</p>

                    <v-btn :loading="sending_sms_to_pnes" block class="validation-button" large @click.prevent="sendSMSToPnes()">
                        {{ $t('global.confirm') }}
                    </v-btn>

                    <v-btn block class="cancel-btn mt-1" large text @click="is_validate_send_sms_to_pnes_dialog_open = false">
                        {{ $t('global.cancel') }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import DeviceInput from '@/components/DeviceInput.vue'
import AssetSummaryComponent from '@/components/AssetSummaryComponent.vue'
import DeviceRepository from '@/repositories/DeviceRepository'
import ModuleRepository from '@/repositories/ModuleRepository'

export default {
    name: 'SendSmsPage',
    components: { AssetSummaryComponent, DeviceInput, Header },
    created() {
        this.resetWeekly()
    },
    data() {
        return {
            asset: {},
            fetching_asset_module: false,
            asset_module: {},
            pnes_device_number: '',
            pnes_device: {},

            days: [
                this.$t('sens_sms_page.monday'),
                this.$t('sens_sms_page.tuesday'),
                this.$t('sens_sms_page.wednesday'),
                this.$t('sens_sms_page.thursday'),
                this.$t('sens_sms_page.friday'),
                this.$t('sens_sms_page.saturday'),
                this.$t('sens_sms_page.sunday'),
            ],
            weekly_preset_selected: 'customConfiguration',
            weekly_preset: [
                {
                    label: this.$t('sens_sms_page.preset_vitogaz'),
                    key: 'vitogaz',
                },
                {
                    label: this.$t('sens_sms_page.preset_antargaz'),
                    key: 'antargaz',
                },
                {
                    label: this.$t('sens_sms_page.preset_custom_configuration'),
                    key: 'customConfiguration',
                },
            ],

            weekly: [],
            modules: [],
            payload: '',

            encoding_sms_to_pnes: false,
            sending_sms_to_pnes: false,
            is_validate_send_sms_to_pnes_dialog_open: false,
        }
    },
    methods: {
        resetForm() {
            this.modules = []
            this.pnes_device_number = ''
            this.pnes_device = {}
            this.payload = ''
            this.resetWeekly()
            this.weekly_preset_selected = 'customConfiguration'
        },

        async getDevicePnesDetails(device) {
            this.pnes_device = device
            this.pnes_device_number = this.pnes_device.device_number
        },

        getPnesAssetInformation(asset) {
            this.asset = asset
            this.prefillForm()
            this.fetchAssetModuleById()
        },

        prefillForm() {
            let config = null
            try {
                config = JSON.parse(this.asset.asset.configuration)
            } catch (e) {
                console.log(e)
            }

            if (config && config.pnes) {
                this.weekly = config.pnes.weekly
                this.modules = config.pnes.modules
            } else {
                DeviceRepository.getLastDecodePnesPayload(this.pnes_device.id_device)
                    .then((success) => {
                        this.weekly = success.data.data.weekly
                        success.data.data.modules.forEach((module) => {
                            this.getModuleDeviceInformation(null, module.details.numDevice)
                        })
                    })
                    .catch((error) => {
                        this.$store.commit('snackbar/showSnackbar', {
                            color: 'warning',
                            text: this.$t('sens_sms_page.no_configuration_found'),
                        })
                    })
            }
        },

        getModuleDeviceInformation(device, device_number) {
            if (!this.modules.find((module) => module.device_number === device_number) && this.modules.length < 12) {
                this.modules.push({
                    device_number: device_number,
                })
            }
        },

        fetchAssetModuleById() {
            this.fetching_asset_module = true
            try {
                ModuleRepository.getModuleById(this.asset.asset.id_module)
                    .then((success) => {
                        this.asset_module = success.data.data
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
                    .finally(() => {
                        this.fetching_asset_module = false
                    })
            } catch (e) {
                console.log(e)
            }
        },

        openValidateSendSmsToPnesDialog() {
            this.encoding_sms_to_pnes = true
            this.postEncodePnesPayload()
                .then(() => {
                    this.is_validate_send_sms_to_pnes_dialog_open = true
                })
                .finally(() => {
                    this.encoding_sms_to_pnes = false
                })
        },

        async postEncodePnesPayload() {
            return DeviceRepository.postEncodePnesPayload({
                modules: this.modules,
                weekly: this.weekly,
            })
                .then((success) => {
                    this.payload = success.data.data
                })
                .catch((error) => {
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'success',
                        text: this.$t('sens_sms_page.configuration_sent'),
                    })
                })
        },

        sendSMSToPnes() {
            this.sending_sms_to_pnes = true
            DeviceRepository.postSendSMSToPnes({
                device_number: this.pnes_device_number,
                modules: this.modules,
                weekly: this.weekly,
                minute_of_hour: 0,
            })
                .then(() => {
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'success',
                        text: this.$t('sens_sms_page.configuration_sent'),
                    })

                    this.$refs.DeviceInput.checkDevice()
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.sending_sms_to_pnes = false
                    this.is_validate_send_sms_to_pnes_dialog_open = false
                })
        },

        activateWeeklyByDayAndHour(day, hour) {
            this.weekly[day][hour] = !this.isActivateWeeklyByDayAndHour(day, hour)
            this.$forceUpdate()
        },

        isActivateWeeklyByDayAndHour(day, hour) {
            return this.weekly[day] ? !!this.weekly[day][hour] : false
        },

        handleWeeklyPresetChange(item) {
            switch (item.key) {
                case 'vitogaz':
                    this.weeklyPresetTwoCallPerDays(6, 15)
                    break
                case 'antargaz':
                    this.weeklyPresetTwoCallPerDays(6, 14)
                    break
                case 'customConfiguration':
                    this.resetWeekly()
                    break
            }
            this.$forceUpdate()
        },

        resetWeekly() {
            Array.from(Array(7)).forEach((day, index) => {
                this.weekly[index] = Array.from(Array(24))
            })
        },

        weeklyPresetTwoCallPerDays(first_hour, second_hour) {
            this.resetWeekly()
            Array.from(Array(7)).forEach((value, index) => {
                this.weekly[index][first_hour] = true
                this.weekly[index][second_hour] = true
            })
        },
    },
}
</script>

<style scoped>
.weekly-table {
    overflow-x: auto;
    width: 100%;

    --background-color: #d4e1e5;
    --background-color-hover: #d4e1e599;
    --background-color-selected: #d4e1e5;
    --border-color: #4c545f33;
    --border-color-selected-hover: #4c545f66;

    & * {
        border-radius: 4px;
    }

    & th {
        background: var(--background-color);
    }

    & td {
        min-width: 40px;
        border: 1px solid var(--border-color);

        &:hover {
            background: var(--background-color-hover);
        }

        &.selected {
            background: var(--background-color-selected);
            border: 1px solid var(--border-color);

            &:hover {
                opacity: 1;
                border: 1px solid var(--border-color-selected-hover);
            }
        }
    }
}
</style>
