<template>
    <div id="device-input">
        <qr-code-scanner-component v-if="scanner" v-on:cancel="scanner = false" v-on:scanned="getDataScanner" />

        <div>
            <div v-if="$store.state.last_devices_entered.length > 0" class="device-history pb-2 px-3 mb-4">
                <p class="text pt-3 mb-1 font-weight-bold history-text">
                    {{ $t('device_input.latest_devices_captured') }}
                </p>
                <p class="text mb-0 history-text">
                    {{ $t('device_input.duplicate_device_field') }}
                </p>

                <v-row class="mt-2 ma-0">
                    <v-col class="pa-0">
                        <v-chip
                            v-for="(last_devices_entered, index) in $store.state.last_devices_entered"
                            :key="index"
                            class="mr-2 mb-1"
                            close
                            color="grey"
                            outlined
                            small
                            @click="selectEnteredDevice(last_devices_entered)"
                            @click:close="$store.commit('removeLastDeviceEntered', index)"
                        >
                            <span class="text-chips">{{ last_devices_entered }}</span>
                        </v-chip>
                    </v-col>
                </v-row>
            </div>

            <v-combobox
                ref="comboboxDevice"
                v-model.trim="selected_device.value"
                :hint="$t('device_input.press_enter_validate')"
                :label="label"
                :loading="loading"
                class="device-input"
                outlined
                persistent-hint
                small-chips
                @change="checkDevice()"
            >
                <template v-slot:selection>
                    <v-chip v-if="selected_device.value !== ''" :color="selected_device.color" class="mt-2" text-color="white">
                        <v-avatar left>
                            <v-icon v-if="selected_device.color === 'error'">mdi-alert-circle</v-icon>
                            <v-icon v-else-if="selected_device.color === 'success'">mdi-checkbox-marked-circle</v-icon>
                            <v-icon v-else>mdi-help-circle</v-icon>
                        </v-avatar>
                        {{ selected_device.value }}
                        <v-icon color="white" right small @click="resetGlobal()">mdi-close-circle</v-icon>
                    </v-chip>
                </template>

                <template v-slot:append>
                    <v-icon append color="blue" @click="openScanner()">mdi-qrcode-scan</v-icon>
                </template>
            </v-combobox>
        </div>
    </div>
</template>

<script>
import DeviceRepository from '../repositories/DeviceRepository'
import QrCodeScannerComponent from '@/components/QrCodeScannerComponent.vue'

export default {
    name: 'DeviceInput',
    components: {
        QrCodeScannerComponent,
        DeviceRepository,
    },
    props: {
        type: {
            type: String,
            require: true,
        },
        label: {
            type: String,
            require: true,
        },
        professional_filter: {
            type: Number | null,
            required: false,
        },
        exclude_device_number: {
            type: String | null,
            required: false,
        },
    },
    created() {
        if (this.$route.query.device_number) {
            this.selected_device.value = this.$route.query.device_number
            this.checkDevice()
        }
    },
    data() {
        return {
            selected_device: {
                value: null,
                color: 'disabled',
            },
            scanner: false,
            loading: false,
        }
    },
    methods: {
        selectEnteredDevice(device) {
            this.selected_device.value = device
            this.copy(device)
            this.checkDevice()
        },

        openScanner() {
            this.scanner = true
            this.hideKeyboard()
        },

        hideKeyboard() {
            //this set timeout needed for case when hideKeyborad
            //is called inside of 'onfocus' event handler
            setTimeout(function () {
                //creating temp field
                var field = document.createElement('input')
                field.setAttribute('type', 'text')
                //hiding temp field from peoples eyes
                //-webkit-user-modify is nessesary for Android 4.x
                field.setAttribute(
                    'style',
                    'position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;',
                )
                document.body.appendChild(field)

                //adding onfocus event handler for out temp field
                field.onfocus = function () {
                    //this timeout of 200ms is nessasary for Android 2.3.x
                    setTimeout(function () {
                        field.setAttribute('style', 'display:none;')
                        setTimeout(function () {
                            document.body.removeChild(field)
                            document.body.focus()
                        }, 14)
                    }, 200)
                }
                //focusing it
                field.focus()
            }, 50)
        },

        checkDevice() {
            this.$emit('resetLocal')
            if (this.selected_device.value === null) {
                this.resetGlobal()
                return
            }
            this.$store.commit('addLastDeviceEntered', this.selected_device.value)
            this.selected_device.color = 'disabled'
            this.loading = true
            this.selected_device.value = this.selected_device.value.replace(/ /g, '')
            //TODO refactor, c'est trop nul
            switch (this.type) {
                case this.type_return:
                    this.checkDeviceToOrder()
                    break
                case this.type_to_return:
                    this.checkDeviceToReturn()
                    break
                case this.type_network:
                    this.getHistoricalTraitedData(false)
                    break
                case this.type_edit:
                    this.getDeviceAsset()
                    break
                case this.type_details:
                    this.getDeviceDetails()
                    break
                case this.type_network_device:
                    this.getNetworkDevice()
                    break
                case this.type_pnes:
                    this.checkDeviceIsPnes()
                    break
                case this.type_pnes_module:
                    this.checkDeviceIsModuleForPnes()
                    break
                case this.type_associate_to_admin:
                    this.checkDeviceCanBeAssociatedToAdmin()
                    break
            }
        },

        getHistoricalTraitedData(refresh) {
            this.loading = true
            DeviceRepository.getHistoricalTraitedData(this.selected_device.value, 10)
                .then((success) => {
                    if (refresh) {
                        this.$emit('getNewHistoricalTraitedData', success.data.data.data)
                    } else {
                        this.$emit('getHistoricalTraitedData', success.data.data.data, this.selected_device.value)
                    }
                    this.selected_device.color = 'success'
                })
                .catch((error) => {
                    this.manageError(error)
                    this.selected_device.color = 'error'
                })
                .finally(() => {
                    this.loading = false
                })
        },

        checkDeviceToOrder() {
            DeviceRepository.checkDeviceToOrder(this.selected_device.value)
                .then((success) => {
                    if (success.data.data.is_orderable) {
                        this.$emit('getDevice', this.selected_device.value, success.data.data.id_professional)
                        this.selected_device.color = 'success'
                    } else {
                        this.$store.commit('snackbar/showSnackbar', {
                            color: 'error',
                            text: this.error_display['device_cant_be_added_to_new_order'],
                        })
                        this.selected_device.color = 'error'
                        this.$emit('reset')
                    }
                })
                .catch((error) => {
                    this.selected_device.color = 'error'
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },

        checkDeviceToReturn() {
            DeviceRepository.checkDeviceToReturn(this.selected_device.value)
                .then((success) => {
                    if (success.data.data.is_returnable) {
                        this.$emit(
                            'getDevice',
                            this.selected_device.value,
                            success.data.data.professional.id_professional,
                            success.data.data.professional.distributor_name,
                        )
                        this.selected_device.color = 'success'
                    } else {
                        this.$emit('reset')
                        this.$store.commit('snackbar/showSnackbar', {
                            color: 'error',
                            text: this.error_display['device_not_returnable'],
                        })
                        this.selected_device.color = 'error'
                    }
                })
                .catch((error) => {
                    this.selected_device.color = 'error'
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },

        getDeviceAsset() {
            DeviceRepository.getDeviceAsset(this.selected_device.value)
                .then((success) => {
                    this.$emit('getDeviceAsset', success.data.data, this.selected_device.value)
                    this.selected_device.color = 'success'
                })
                .catch((error) => {
                    this.selected_device.color = 'error'
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },

        getDeviceAssetForPnes() {
            DeviceRepository.getDeviceByDeviceNumber(this.selected_device.value)
                .then(async (success) => {
                    this.selected_device.color = 'success'
                    if (!success.data.data.phone_number) {
                        this.$emit('reset')
                        this.$store.commit('snackbar/showSnackbar', {
                            color: 'error',
                            text: this.error_display['pnes_without_phone_number'],
                        })
                        this.selected_device.color = 'error'
                    } else {
                        this.$emit('getDeviceDetails', success.data.data, this.selected_device.value)

                        await DeviceRepository.getDeviceAsset(this.selected_device.value).then((success) => {
                            this.$emit('getDeviceAsset', success.data.data, this.selected_device.value)
                        }).catch((error) => {
                            console.log(error)
                            this.$emit('getDeviceAsset', {}, this.selected_device.value)
                        })
                    }
                })
                .catch((error) => {
                    this.$emit('reset')
                    this.selected_device.color = 'error'
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },

        getDeviceDetails() {
            DeviceRepository.getDeviceByDeviceNumber(this.selected_device.value)
                .then((success) => {
                    this.$emit('getDeviceDetails', success.data.data, this.selected_device.value)
                    this.selected_device.color = 'success'
                })
                .catch((error) => {
                    this.selected_device.color = 'error'
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },

        getDataScanner(value, qsd) {
            console.log(value)
            console.log(qsd)
            this.scanner = false
            this.selected_device.value = value
            this.checkDevice()
        },

        getNetworkDevice() {
            DeviceRepository.getDeviceByDeviceNumber(this.selected_device.value)
                .then((success) => {
                    if (success.data.data.id_professional !== this.professional_filter) {
                        this.$store.commit('snackbar/showSnackbar', {
                            color: 'error',
                            text: this.error_display['device_and_network_device_professional_are_different'],
                        })
                        this.selected_device.color = 'error'
                    } else if (success.data.data.id_asset) {
                        this.$store.commit('snackbar/showSnackbar', {
                            color: 'error',
                            text: this.error_display['network_device_already_installed'],
                        })
                        this.selected_device.color = 'error'
                    } else if (this.exclude_device_number !== null && success.data.data.device_number === this.exclude_device_number) {
                        this.$store.commit('snackbar/showSnackbar', {
                            color: 'error',
                            text: this.error_display['network_device_already_installed'],
                        })
                        this.selected_device.color = 'error'
                    } else {
                        this.$emit('getNetworkDevice', success.data.data, this.selected_device.value)
                        this.selected_device.color = 'success'
                    }
                })
                .catch((error) => {
                    this.selected_device.color = 'error'
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },

        checkDeviceIsPnes() {
            const pnesRegex = new RegExp('^(53225299|5322..A4)')
            if (!pnesRegex.test(this.selected_device.value)) {
                this.$store.commit('snackbar/showSnackbar', {
                    color: 'error',
                    text: this.error_display['not_pnes'],
                })
                this.selected_device.color = 'error'
                this.loading = false
            } else {
                this.getDeviceAssetForPnes()
            }
        },
        checkDeviceIsModuleForPnes() {
            const moduleRegex = new RegExp('^.{6}(85|86|87|89|D1|E0)')
            if (!moduleRegex.test(this.selected_device.value)) {
                this.$store.commit('snackbar/showSnackbar', {
                    color: 'error',
                    text: this.error_display['not_module_pnes'],
                })
                this.selected_device.color = 'error'
                this.loading = false
            } else {
                this.getDeviceDetails()
            }
        },

        checkDeviceCanBeAssociatedToAdmin() {
            let authorizedIsInStock = [1, 2, -4, -6]
            this.loading = true
            DeviceRepository.getDeviceByDeviceNumber(this.selected_device.value)
                .then((success) => {
                    if (!authorizedIsInStock.includes(success.data.data.is_in_stock)) {
                        this.$emit('getDevice', null)
                        this.selected_device.color = 'error'
                        this.$store.commit('snackbar/showSnackbar', {
                            color: 'error',
                            text: this.$t('associate_device.authorized_status_error_message'),
                        })
                    } else {
                        this.$emit('getDevice', success.data.data, this.selected_device.value)
                        this.selected_device.color = 'success'
                    }
                })
                .catch((error) => {
                    this.selected_device.color = 'error'
                    this.$emit('getDevice', null)
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        resetGlobal() {
            this.resetLocal()
            this.$emit('reset')
        },

        resetLocal() {
            this.selected_device = {
                value: '',
                color: 'disabled',
            }
        },
    },
}
</script>

<style>
.device-history {
    background-color: #d4e1e5;
}

.history-text {
    line-height: 8px !important;
}

.text-chips {
    color: #4c545f !important;
}
</style>
