import axios from '@/plugins/axios'
import { AxiosResponse } from 'axios'

export default {
    getAddressFromGoogleApi(latitude, longitude): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAEIWCCqmMzzsUAwZKhL8_GWJzo_2LKxN4`,
            {
                transformRequest: (data, headers) => {
                    // prevent the header from being added by default
                    delete headers.common['Authorization']
                    // some libraries might set it directly as well, e.g. nuxtjs/auth
                    delete headers['Authorization']
                },
            },
        )
    },
}
