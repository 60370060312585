<template>
    <div id="send-downlink">
        <v-container>
            <Header :title="$t('send_downlink.send_downlink')" />

            <device-input
                ref="deviceToBind"
                :label="$t('send_downlink.device_number')"
                :type="type_details"
                class="mt-4"
                v-on:getDeviceDetails="getAllDeviceDownlinks"
                v-on:reset="reset"
                v-on:resetLocal="reset"
            />

            <v-expand-transition>
                <div v-if="display_downlink_information">
                    <v-data-table
                        :headers="headers_next_downlinks"
                        :items="next_downlinks"
                        :loading="loading_next_downlinks"
                        :server-items-length="total_next_downlinks"
                        class="mt-4"
                    >
                        <template v-slot:top>
                            <div class="px-4 py-2 d-flex justify-space-between">
                                <v-toolbar-title>
                                    {{ $t('send_downlink.next_downlinks') }}
                                </v-toolbar-title>

                                <v-spacer />

                                <v-btn class="validation-button" @click="openDialogEditDownlink(null, false)">
                                    {{ $t('global.add') }}
                                </v-btn>
                            </div>

                            <div class="pl-4 mt-n4">
                                <span class="font-14 pr-1"> {{ $t('send_downlink.next_theoric_data') }} : </span>
                                <v-chip outlined small>
                                    {{ is_estimate_next_statement_passed ? $t('send_downlink.coming_soon') : next_emission_timeout }}
                                </v-chip>
                            </div>
                        </template>

                        <template v-slot:item.date_trigger="{ item }">
                            <v-chip v-if="item.downlink_type !== 'CURRENT_PERMANENT'" class="my-4" outlined>
                                <span>{{ moment(item.date_trigger).format('DD/MM/Y HH:mm:ss') }}</span>
                            </v-chip>
                        </template>

                        <template v-slot:item.downlink_type="{ item }">
                            <span v-if="item.type === 'PERMANENT'">
                                {{ $t('send_downlink.permanent') }}
                                <span v-if="device.is_default_config_downlink && item.type === 'CURRENT_PERMANENT'"> (Downlink du DR)</span>
                            </span>
                            <span v-else-if="item.type === 'RECURRING'">{{ $t('send_downlink.recurrent') }}</span>
                            <span v-else-if="item.type === 'ONE_SHOT'">{{ $t('send_downlink.unique') }}</span>
                            <span v-else>Inconnu</span>
                        </template>

                        <template v-slot:item.actions="{ item }">
                            <template v-if="item.downlink_type !== 'CURRENT_PERMANENT'">
                                <v-icon class="pointer mr-2" @click="openDialogEditDownlink(item, item.downlink_type === 'PERMANENT')">
                                    mdi-pencil
                                </v-icon>
                                <v-icon class="pointer" @click="openDialogDeleteDownlink(item)"> mdi-delete </v-icon>
                            </template>
                        </template>
                    </v-data-table>

                    <v-data-table :headers="headers_help_downlinks" :items="help_downlinks" :loading="loading_help_downlinks" class="mt-4">
                        <template v-slot:top>
                            <v-toolbar elevation="0">
                                <v-toolbar-title>{{ $t('send_downlink.help_downlinks') }}</v-toolbar-title>
                            </v-toolbar>
                        </template>

                        <template v-slot:item.downlink_type="{ item }">
                            <span>{{ $t(`send_downlink.${item.downlink_type}`) }}</span>
                        </template>

                        <template v-slot:item.actions="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="mr-2 pointer" v-bind="attrs" @click="copy(item.downlink)" v-on="on">
                                        mdi-content-copy
                                    </v-icon>
                                </template>
                                {{ $t('send_downlink.copy_downlink') }}
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        class="mr-2 pointer"
                                        v-bind="attrs"
                                        @click="
                                            openDialogEditDownlink(
                                                {
                                                    downlink_message: item.downlink,
                                                    downlink_type: item.downlink_type,
                                                },
                                                true,
                                            )
                                        "
                                        v-on="on"
                                    >
                                        mdi-link-edit
                                    </v-icon>
                                </template>
                                Envoyer ce downlink
                            </v-tooltip>
                        </template>
                    </v-data-table>

                    <v-data-table
                        :headers="headers_history_downlinks"
                        :items="history_downlinks"
                        :loading="loading_history_downlinks"
                        :server-items-length="total_history_downlinks"
                        class="mt-4"
                    >
                        <template v-slot:top>
                            <v-toolbar elevation="0">
                                <v-toolbar-title>{{ $t('send_downlink.downlink_history') }}</v-toolbar-title>
                            </v-toolbar>
                        </template>

                        <template v-slot:item.date_trigger="{ item }">
                            <v-chip class="my-4" outlined>
                                <span>{{ moment(item.date_trigger).format('DD/MM/Y HH:mm:ss') }}</span>
                            </v-chip>
                        </template>

                        <template v-slot:item.downlink_type="{ item }">
                            <span v-if="item.type === 'RECURRING'">{{ $t('send_downlink.recurrent') }}</span>
                            <span v-else-if="item.type === 'ONE_SHOT'">{{ $t('send_downlink.unique') }}</span>
                            <span v-else-if="item.type === 'PERMANENT'">{{ $t('send_downlink.PERMANENT') }}</span>
                            <span v-else>Inconnu</span>
                        </template>
                    </v-data-table>
                </div>
            </v-expand-transition>

            <v-dialog v-model="dialog_edit_downlink" max-width="600">
                <v-form ref="form_edit_downlink">
                    <v-card>
                        <v-card-title>
                            {{ $t('send_downlink.downlink_to_send') }}
                        </v-card-title>

                        <v-card-text class="pb-0">
                            <v-text-field
                                v-model="downlink_message"
                                :label="'Downlink'"
                                :rules="downlink_type !== 'PERMANENT' ? require_field : []"
                                filled
                            />

                            <v-select
                                v-model="downlink_type"
                                :disabled="disable_downlink_type"
                                :items="downlink_types"
                                :label="$t('send_downlink.downlink_type')"
                                :menu-props="{ bottom: true, offsetY: true }"
                                :rules="require_field"
                                filled
                                item-text="value"
                                item-value="key"
                            />

                            <p v-if="downlink_type === 'ONE_SHOT'">
                                {{ $t('send_downlink.one_shot_information') }}
                            </p>
                            <p v-else-if="downlink_type === 'RECURRING'">
                                {{ $t('send_downlink.recurring_instruction') }}
                            </p>

                            <template v-if="downlink_type === 'ONE_SHOT' || downlink_type === 'PERMANENT'">
                                <v-datetime-picker
                                    v-model="date_trigger_downlink"
                                    :date-picker-props="datePickerProps"
                                    :label="$t('send_downlink.schedule_delivery')"
                                    :rules="require_field"
                                    :text-field-props="textFieldProps"
                                    :time-picker-props="timeProps"
                                    date-format="dd/MM/yyyy"
                                    filled
                                    time-format="HH:mm:ss"
                                >
                                    <template v-slot:dateIcon="{ item }">
                                        <v-icon color="#1ba0db">mdi-calendar</v-icon>
                                    </template>

                                    <template v-slot:timeIcon="{ item }">
                                        <v-icon color="#1ba0db">mdi-clock</v-icon>
                                    </template>

                                    <template v-slot:actions="{ parent }">
                                        <v-btn block class="validation-button mb-4 mt-4" @click="parent.okHandler">
                                            {{ $t('global.confirm') }}
                                        </v-btn>
                                    </template>
                                </v-datetime-picker>
                            </template>

                            <template v-else-if="downlink_type === 'RECURRING'">
                                <v-text-field
                                    v-model="periodicity"
                                    :label="$t('send_downlink.reference_j')"
                                    :rules="require_field"
                                    filled
                                    type="number"
                                />
                            </template>

                            <v-text-field v-model="description" :label="$t('send_downlink.description')" filled />
                        </v-card-text>

                        <v-container class="pt-0 px-4">
                            <v-btn :loading="loading_edit_downlink" block class="validation-button" @click.prevent="prepareEditDownlink()">
                                {{ $t('global.confirm') }}
                            </v-btn>

                            <v-btn block class="cancel-btn mt-2" text @click="dialog_edit_downlink = false">
                                {{ $t('global.cancel') }}
                            </v-btn>
                        </v-container>
                    </v-card>
                </v-form>
            </v-dialog>

            <v-dialog v-model="dialog_delete_downlink" max-width="600">
                <v-card>
                    <v-card-title>
                        {{ $t('send_downlink.delete_downlink') }}
                    </v-card-title>

                    <v-card-text class="pb-0">
                        <p>
                            {{ $t('send_downlink.confirm_delete_downlink') }}
                            <b>{{ downlink_to_delete ? downlink_to_delete.downlink_message : '' }}</b> ?
                        </p>
                    </v-card-text>

                    <v-container>
                        <v-btn :loading="loading_delete_downlink" block class="validation-button" @click.prevent="deleteDownlink()">
                            {{ $t('global.confirm') }}
                        </v-btn>

                        <v-btn block class="cancel-btn mt-2" text @click="dialog_delete_downlink = false">
                            {{ $t('global.cancel') }}
                        </v-btn>
                    </v-container>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import DeviceInput from '@/components/DeviceInput.vue'
import DeviceRepository from '@/repositories/DeviceRepository'
import moment from '../../plugins/moment'
import ColorPickerComponent from '@/components/ColorPickerComponent.vue'
import DownlinkRepository from '@/repositories/DownlinkRepository'

export default {
    components: { ColorPickerComponent, DeviceInput, Header },
    data() {
        return {
            device: null,
            configuration_downlink_device: '',
            interval: null,
            is_estimate_next_statement_passed: false,
            next_emission_timeout: null,
            display_downlink_information: false,

            loading_next_downlinks: false,
            headers_next_downlinks: [
                { text: 'Downlink', value: 'downlink_message' },
                { text: this.$t('send_downlink.program_for'), value: 'date_trigger' },
                { text: this.$t('send_downlink.downlink_type'), value: 'downlink_type' },
                { text: this.$t('send_downlink.frequency'), value: 'periodicity' },
                { text: this.$t('send_downlink.description'), value: 'label' },
                { text: this.$t('send_downlink.actions'), value: 'actions' },
            ],
            next_downlinks: [],
            total_next_downlinks: 0,

            loading_help_downlinks: false,
            headers_help_downlinks: [
                { text: this.$t('send_downlink.label'), value: 'label' },
                { text: this.$t('send_downlink.downlink_type'), value: 'downlink_type' },
                { text: 'Downlink', value: 'downlink' },
                { text: this.$t('send_downlink.description'), value: 'description' },
                { text: this.$t('send_downlink.actions'), value: 'actions' },
            ],
            help_downlinks: [],

            loading_history_downlinks: false,
            headers_history_downlinks: [
                { text: 'Downlink', value: 'downlink_message' },
                { text: this.$t('send_downlink.date_send'), value: 'date_trigger' },
                { text: this.$t('send_downlink.downlink_type'), value: 'downlink_type' },
                { text: this.$t('send_downlink.reference_j'), value: 'periodicity' },
                { text: this.$t('send_downlink.description'), value: 'label' },
            ],
            history_downlinks: [],
            total_history_downlinks: 0,

            dialog_edit_downlink: false,
            loading_edit_downlink: false,
            disable_downlink_type: false,
            id_downlink: null,
            date_trigger_downlink: '',
            downlink_message: '',
            description: '',
            periodicity: '',

            downlink_types: [
                {
                    key: 'ONE_SHOT',
                    value: this.$t('send_downlink.one_time'),
                },
                {
                    key: 'RECURRING',
                    value: this.$t('send_downlink.recurrent'),
                },
                {
                    key: 'PERMANENT',
                    value: this.$t('send_downlink.PERMANENT'),
                },
            ],
            downlink_type: 'ONE_SHOT',

            textFieldProps: {
                prependInnerIcon: 'mdi-calendar',
                filled: true,
                rules: [(v) => !!v || this.$t('send_downlink.required_field')],
            },
            datePickerProps: {
                headerColor: '#1ba0db',
                min: new Date().toISOString().substr(0, 10),
            },
            timeProps: {
                headerColor: '#1ba0db',
                format: '24hr',
            },
            require_field: [(v) => !!v || this.$t('send_downlink.required_field')],

            dialog_delete_downlink: false,
            loading_delete_downlink: false,
            downlink_to_delete: null,
        }
    },
    methods: {
        moment,

        getAllDeviceDownlinks(data) {
            clearInterval(this.interval)
            this.device = data
            this.prepareGetNextEmission()
            this.getFutureDeviceDownlinkDatatable()
            this.getPastDeviceDownlinkDatatable()
        },

        getFutureDeviceDownlinkDatatable() {
            this.loading_next_downlinks = true

            DeviceRepository.getDeviceDownlinkDatatable(this.device.id_device, false)
                .then((response) => {
                    this.next_downlinks = response.data.data.data
                    this.next_downlinks.unshift({
                        downlink_message: this.device.config_downlink,
                        downlink_type: 'CURRENT_PERMANENT',
                        label: this.$t('send_downlink.current_permanent_downlink'),
                        type: 'PERMANENT',
                    })

                    this.total_next_downlinks = response.data.data.total_rows + 1
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_next_downlinks = false
                })
        },

        getPastDeviceDownlinkDatatable() {
            this.loading_history_downlinks = true

            DeviceRepository.getDeviceDownlinkDatatable(this.device.id_device, true)
                .then((response) => {
                    this.loading_help_downlinks = true
                    this.getDefaultDownlinkByIdDeviceReferential()

                    this.history_downlinks = response.data.data.data
                    this.total_history_downlinks = response.data.data.total_rows

                    this.display_downlink_information = true
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_history_downlinks = false
                })
        },

        prepareGetNextEmission() {
            this.getNextEmission()
            this.interval = setInterval(() => {
                this.getNextEmission()
            }, 1000)
        },

        getNextEmission() {
            let timeout =
                (24 / this.device.total_statement_per_day) * 3600 -
                moment(new Date()).diff(new Date(this.device.date_last_emission), 'second')
            this.is_estimate_next_statement_passed = timeout <= 0

            this.next_emission_timeout = moment.utc(moment.duration(timeout, 'second').asMilliseconds()).format('HH:mm:ss')
        },

        getDefaultDownlinkByIdDeviceReferential() {
            DownlinkRepository.getDefaultDownlinkByIdDeviceReferential(this.device.device_referentiel.id_device_referentiel)
                .then((response) => {
                    if (response.data.data) {
                        this.help_downlinks = response.data.data
                    }
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_help_downlinks = false
                })
        },

        openDialogEditDownlink(downlink, disableDownlinkType) {
            this.disable_downlink_type = disableDownlinkType

            if (downlink) {
                this.id_downlink = downlink.id_downlink
                this.downlink_message = downlink.downlink_message
                this.description = downlink.label
                this.downlink_type = downlink.type

                if (this.downlink_type === 'RECURRING') {
                    this.periodicity = downlink.periodicity
                } else if (this.downlink_type === 'ONE_SHOT' || this.downlink_type === 'PERMANENT') {
                    downlink.date_trigger = downlink.date_trigger ? downlink.date_trigger : moment().format('Y-MM-DD HH:mm:ss')
                    this.date_trigger_downlink = moment(downlink.date_trigger).format('DD/MM/yyyy HH:mm:ss')
                }
            } else {
                this.resetDownlinkObject()
                this.date_trigger_downlink = moment().format('DD/MM/yyyy HH:mm:ss')
            }

            this.dialog_edit_downlink = true

            if (!downlink && this.$refs.form_edit_downlink) {
                this.$refs.form_edit_downlink.reset()
                this.downlink_type = 'ONE_SHOT'
            }
        },

        prepareEditDownlink() {
            if (this.$refs.form_edit_downlink.validate()) {
                if (this.id_downlink) {
                    this.updateDownlink()
                } else {
                    this.addNewDownlink()
                }
            }
        },

        addNewDownlink() {
            this.loading_edit_downlink = true

            DownlinkRepository.postAddNewDownlink(this.device.id_device, {
                downlink_message: this.downlink_message,
                downlink_type: this.downlink_type,
                date_trigger:
                    this.downlink_type === 'ONE_SHOT' || this.downlink_type === 'PERMANENT'
                        ? moment(this.date_trigger_downlink, 'DD/MM/yyyy HH:mm:ss').format('Y-MM-DD HH:mm:ss')
                        : null,
                periodicity: this.downlink_type === 'RECURRING' ? this.periodicity : null,
                label: this.description,
            })
                .then(() => {
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'success',
                        text: this.$t('send_downlink.downlink_added'),
                    })

                    this.resetDownlinkObject()
                    this.getFutureDeviceDownlinkDatatable()
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_edit_downlink = false
                    this.dialog_edit_downlink = false
                })
        },

        updateDownlink() {
            this.loading_edit_downlink = true

            DownlinkRepository.putUpdateDownlink(this.id_downlink, {
                downlink_message: this.downlink_message,
                downlink_type: this.downlink_type,
                date_trigger:
                    this.downlink_type === 'ONE_SHOT' || this.downlink_type === 'PERMANENT'
                        ? moment(this.date_trigger_downlink, 'DD/MM/yyyy HH:mm:ss').format('Y-MM-DD HH:mm:ss')
                        : null,
                periodicity: this.downlink_type === 'RECURRING' ? this.periodicity : null,
                label: this.description,
            })
                .then(() => {
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'success',
                        text: this.$t('send_downlink.downlink_updated'),
                    })

                    this.resetDownlinkObject()
                    this.getFutureDeviceDownlinkDatatable()
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_edit_downlink = false
                    this.dialog_edit_downlink = false
                })
        },

        resetDownlinkObject() {
            this.id_downlink = null
            this.downlink_message = ''
            this.date_trigger_downlink = ''
            this.description = ''
            this.periodicity = ''
            this.downlink_type = 'ONE_SHOT'
        },

        openDialogDeleteDownlink(downlink) {
            this.downlink_to_delete = downlink
            this.dialog_delete_downlink = true
        },

        deleteDownlink() {
            this.loading_delete_downlink = true

            DownlinkRepository.deleteDownlink(this.downlink_to_delete.id_downlink)
                .then(() => {
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'success',
                        text: this.$t('send_downlink.downlink_deleted'),
                    })

                    this.downlink_to_delete = null
                    this.getFutureDeviceDownlinkDatatable()
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.dialog_delete_downlink = false
                    this.loading_delete_downlink = false
                })
        },

        reset() {
            clearInterval(this.interval)
            this.device = null
            this.display_downlink_information = false
        },
    },
    mounted() {
        if (this.$route.params.device_number) {
            this.$refs.deviceToBind.selected_device.value = this.$route.params.device_number
            this.$refs.deviceToBind.checkDevice()
        }
    },
}
</script>
