<template>
    <div id="check-network">
        <v-container>
            <Header
                :text="$t('link_network_device.network_device_to_link')"
                :title="$t('dashboard.link_gateway_repeater_pnes')"
            />

            <span class="font-weight-medium font-14">
                <v-icon class="pr-2">mdi-wifi-settings</v-icon>
                {{ $t('link_network_device.device_to_link') }}
            </span>

            <device-input
                ref="DeviceToLink"
                :label="$t('link_network_device.device_to_link_to_network')"
                :type="this.type_edit"
                class="mt-3"
                v-on:getDeviceAsset="getDeviceToLinkToNetwork"
                v-on:reset="resetDevice"
                v-on:resetLocal="resetDevice"
            />

            <v-expand-transition>
                <div class="mt-5"  v-if="selected_device_to_link !== ''">
                    <span class="font-weight-medium font-14">
                        <v-icon class="pr-2">mdi-access-point-network</v-icon>
                        {{ $t('link_network_device.network_device') }}
                    </span>

                    <device-input
                        ref="NetworkDeviceToLink"
                        :label="$t('link_network_device.network_device_number')"
                        :type="this.type_network_device"
                        :professional_filter="this.selected_device_to_link.asset.id_professional"
                        class="mt-3"
                        v-on:getNetworkDevice="getNetworkDevice"
                        v-on:reset="resetNetworkDevice"
                        v-on:resetLocal="resetNetworkDevice"
                    />
                    <v-btn
                        :class="invalidForm ? 'mt-2' : 'validation-button mt-2'"
                        :disabled="invalidForm"
                        block
                        large
                        @click="dialog_link_device_network = true"
                    >
                        {{ $t('global.validate') }}
                    </v-btn>
                </div>
            </v-expand-transition>
        </v-container>
        <v-dialog v-model="dialog_link_device_network" max-width="500px">
            <v-card>
                <v-card-title>
                    {{ $t('dashboard.add_network_asset') }}
                </v-card-title>

                <v-card-text class="pt-4 pb-0">
                    {{ $t('link_network_device.you_are_installing_network_device') }} <span class="text-green"><strong>{{ selected_network_device.device_number }}</strong></span><br>{{ $t('link_network_device.to_desk') }}: <strong>{{ deskName }}</strong><br>
                    {{ $t('link_network_device.to_sens') }}: <strong>{{ sensName }}</strong><br>
                    {{ $t('global.confirm') }}: <strong>{{siteName}}</strong><br>
                </v-card-text>

                <v-container class="px-6">
                    <v-btn :loading="is_loading_confirm_button"  block class="validation-button" color="primary" large @click.prevent="confirmInstallNetworkDevice">
                        {{ $t('global.confirm') }}
                    </v-btn>

                    <v-btn block class="cancel-btn mt-1" large text @click="dialog_link_device_network = false">
                        {{ $t('global.cancel') }}
                    </v-btn>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Header from '../../components/Header'
import DeviceInput from '../../components/DeviceInput'
import DeviceRepository from '../../repositories/DeviceRepository'

export default {
    name: 'LinkNetworkDevice',
    components: {
        Header,
        DeviceInput,
    },
    data() {
        return {
            selected_device_to_link: '',
            selected_network_device: '',
            dialog_link_device_network: false,
            is_loading_confirm_button: false,
        }
    },
    computed: {
        invalidForm: function() {
            return this.selected_device_to_link === '' || this.selected_network_device === '' || this.selected_network_device.id_professional !== this.selected_device_to_link.client.id_professional
        },
        sensName: function(){
            return (this.selected_device_to_link === '' || this.selected_device_to_link.client === null ? '' : this.selected_device_to_link.client.client_space_name)
        },
        deskName: function() {
            return (this.selected_network_device === '' ? '' : this.selected_network_device.professional.distributor_name)
        },
        siteName: function() {
            return (this.selected_device_to_link === '' ? '' : this.selected_device_to_link.asset.address)
        },
    },
    methods: {
        getDeviceToLinkToNetwork(value) {
            this.selected_device_to_link = value
        },
        getNetworkDevice(value) {
            this.selected_network_device = value
        },
        resetDevice() {
            this.resetNetworkDevice()
            this.selected_device_to_link = ''
        },
        resetNetworkDevice() {
            this.selected_network_device = ''
        },
        confirmInstallNetworkDevice() {
            this.is_loading_confirm_button = true;
            DeviceRepository.postInstallNetworkDevice(this.selected_network_device.device_number, this.selected_device_to_link.asset.domicile.id_domicile)
                .then(() => {
                    this.dialog_link_device_network = false
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'success',
                        text:  this.$t('link_network_device.network_device_installed'),
                    })
                    this.resetDevice()
                    this.$refs.DeviceToLink.resetLocal()
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.is_loading_confirm_button = false
                })
        }
    },
}
</script>

<style>

</style>
