<template>
    <div id="add-edit-client-domicile-dialog-component">
        <v-dialog v-model="is_add_edit_domicile_dialog_open" max-width="800">
            <v-card>
                <v-card-title>
                    {{
                        $t(
                            is_new_domicile
                                ? 'add_edit_client_domicile_dialog_component.add_site'
                                : 'add_edit_client_domicile_dialog_component.edit_site',
                        )
                    }}
                </v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <div class="mb-4">
                            <div class="mb-2">
                                <v-expand-transition>
                                    <div v-if="select_address_by_map">
                                        <map-address-component v-on:change:address="handleAddressChange" />

                                        <div class="text-end font-12 mt-n4">
                                            <p class="mb-n1 text-skin-medium">
                                                {{ $t('add_edit_client_domicile_dialog_component.cant_find_address') }}
                                            </p>
                                            <a class="text-decoration-underline" @click="handleAddressSelectionChange">
                                                {{ $t('add_edit_client_domicile_dialog_component.enter_your_address') }}
                                            </a>
                                        </div>
                                    </div>
                                </v-expand-transition>

                                <v-expand-transition>
                                    <div v-if="!select_address_by_map">
                                        <autocomplete-component v-on:change:address="handleAddressChange" />

                                        <div class="text-end font-12">
                                            <p class="mb-n1 text-skin-medium">
                                                {{ $t('add_edit_client_domicile_dialog_component.cant_find_address') }}
                                            </p>
                                            <a class="text-decoration-underline" @click="handleAddressSelectionChange">
                                                {{ $t('add_edit_client_domicile_dialog_component.placing_point_on_map') }}
                                            </a>
                                        </div>
                                    </div>
                                </v-expand-transition>
                            </div>

                            <v-text-field
                                v-model="domicile.address_name"
                                :label="$t('add_edit_client_domicile_dialog_component.site_name')"
                                :rules="[(v) => !!v || this.$t('generic_form_component.required_fields')]"
                                filled
                            />
                            <v-text-field
                                v-model="domicile.reference"
                                :label="$t('add_edit_client_domicile_dialog_component.site_reference')"
                                filled
                            />
                        </div>

                        <edit-contact-on-site-component :contact_on_site="contact_on_site" />

                        <v-btn
                            :loading="adding_domicile"
                            block
                            class="validation-button"
                            color="primary"
                            large
                            @click.prevent="prepareAddressObject()"
                        >
                            {{ $t('global.confirm') }}
                        </v-btn>

                        <v-btn block class="cancel-btn mt-1" large text @click="is_add_edit_domicile_dialog_open = false">
                            {{ $t('global.cancel') }}
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import AutocompleteComponent from '@/components/site/AutocompleteComponent.vue'
import MapAddressComponent from '@/components/site/MapAddressComponent.vue'
import EditContactOnSiteComponent from '@/components/site/EditContactOnSiteComponent.vue'
import googleMap from '@/mixin/googleMap'
import DomicileRepository from '@/repositories/DomicileRepository'

export default {
    name: 'AddEditClientDomicileDialogComponent',
    components: { EditContactOnSiteComponent, MapAddressComponent, AutocompleteComponent },
    mixins: [googleMap],
    data() {
        return {
            is_add_edit_domicile_dialog_open: false,
            is_new_domicile: false,
            adding_domicile: false,

            select_address_by_map: false,
            google_address: {},
            client: {},
            domicile: {
                address_name: '',
                reference: '',
            },
            address_name: '',
            contact_on_site: {
                contact_last_name: '',
                contact_first_name: '',
                contact_mail: '',
                contact_phone: '',
                contact_note: '',
            },
        }
    },
    methods: {
        openAddClientDomicileDialog(client) {
            this.client = client
            this.is_add_edit_domicile_dialog_open = true
            this.is_new_domicile = true
        },

        handleAddressSelectionChange() {
            this.select_address_by_map = !this.select_address_by_map
            this.google_address = {}
        },

        handleAddressChange(address) {
            if (googleMap.methods.isAddressValid(address)) {
                this.google_address = address
            } else {
                this.$store.commit('snackbar/showSnackbar', {
                    color: 'warning',
                    text: this.$t('add_edit_client_domicile_dialog_component.invalid_address'),
                })
            }
        },

        async prepareAddressObject() {
            const valid = await this.$refs.form.validate()
            if (valid && this.google_address.formatted_address) {
                let domicile = this.buildApiDomicileObject(this.google_address, {
                    ...{
                        domicile_name: this.domicile.address_name,
                        reference: this.domicile.reference,
                        id_client: this.client.id_client,
                    },
                    ...this.contact_on_site,
                })
                this.addClientDomicile(domicile)
            }
        },

        addClientDomicile(domicileApi) {
            this.adding_domicile = true
            DomicileRepository.addDomicile(domicileApi)
                .then(() => {
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'success',
                        text: this.$t('add_edit_client_domicile_dialog_component.site_added'),
                    })
                    this.$emit('added:domicile')
                    this.is_add_edit_domicile_dialog_open = false
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.adding_domicile = false
                })
        },
    },
}
</script>
