<template>
    <div v-if="asset.id_asset">
        <div id="equipment_details">
            <span class="section_title">
                {{ $t('edit_equipment.equipment_information') }}
            </span>

            <v-text-field v-model="asset.name" :label="$t('edit_equipment.equipment_name')" :rules="require_field" class="mt-2" filled />

            <v-select
                v-model="asset.id_liquid"
                :disabled="asset_liquids.length === 0"
                :items="asset_liquids"
                :label="$t('edit_equipment.content')"
                :loading="loading_content"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="require_field"
                item-text="name"
                item-value="id_asset_liquid"
                outlined
            >
                <template v-slot:append-item>
                    <v-btn block size="large" text @click="openAddAssetLiquidDialog()">
                        <v-icon>mdi-plus</v-icon>
                        {{ $t('global.add') }}
                    </v-btn>
                </template>
            </v-select>
        </div>

        <manage-asset-image-component :asset="asset" />

        <div id="equipment_model">
            <span class="section_title">{{ $t('edit_equipment.equipment_model') }}</span>
            <v-switch v-model="know_model" class="mt-1" color="info">
                <template v-slot:label>
                    <span class="switch-label">
                        {{ $t('edit_equipment.know_equipment_model') }}
                    </span>
                </template>
            </v-switch>

            <div v-if="know_model" class="know_model">
                <v-select
                    v-model="selected_brand_reference"
                    :disabled="brand_reference.length === 0"
                    :items="brand_reference"
                    :label="$t('edit_equipment.equipment_brand')"
                    :loading="loading_brand"
                    :menu-props="{ bottom: true, offsetY: true }"
                    item-text="name"
                    item-value="id_brand_reference"
                    outlined
                    @change="getModelReference()"
                />

                <v-select
                    v-model="selected_model_reference"
                    :disabled="model_reference.length === 0"
                    :items="model_reference"
                    :label="$t('edit_equipment.model_name')"
                    :loading="loading_model"
                    :menu-props="{ bottom: true, offsetY: true }"
                    item-text="trade_name"
                    item-value="id_model_reference"
                    outlined
                    return-object
                />

                <label class="text">
                    {{ $t('edit_equipment.default_offset_model') }} :
                    {{ selected_model_reference.offset }}
                    <v-text-field v-model="model_offset" :label="$t('edit_equipment.offset')" :rules="require_field" filled type="number" />

                    <v-text-field
                        v-model="model_offset_bottom"
                        :label="$t('edit_equipment.offset_bottom')"
                        :rules="require_field"
                        filled
                        type="number"
                    />
                </label>
            </div>

            <div v-else id="unknow-model">
                <v-radio-group v-model="asset.type" class="mt-0">
                    <v-row class="justify-center text-center">
                        <v-col class="radio-tank" cols="3">
                            <v-radio :value="3" class="justify-center mb-0" />
                            <v-img class="ml-auto mr-auto mt-2 mb-2" max-height="150px" max-width="150px" />
                            <p class="img-text">
                                {{ $t('edit_equipment.vertical_cylindrical') }}
                            </p>
                        </v-col>

                        <v-col class="radio-tank" cols="3">
                            <v-radio :value="1" class="justify-center mb-0" />
                            <v-img class="ml-auto mr-auto mt-2 mb-2" max-height="150px" max-width="150px" />
                            <p class="img-text">
                                {{ $t('edit_equipment.rectangular') }}
                            </p>
                        </v-col>

                        <v-col class="text-center radio-tank" cols="3">
                            <v-radio :value="5" class="justify-center mb-0" />
                            <v-img class="ml-auto mr-auto mt-2 mb-2" max-height="150px" max-width="150px" />
                            <p class="img-text">
                                {{ $t('edit_equipment.ibc') }}
                            </p>
                        </v-col>

                        <v-col class="text-center radio-tank" cols="3">
                            <v-radio :value="2" class="justify-center mb-0" />
                            <v-img class="ml-auto mr-auto mt-2 mb-2" max-height="150px" max-width="150px" />
                            <p class="img-text">
                                {{ $t('edit_equipment.cylindrical_lying') }}
                            </p>
                        </v-col>
                    </v-row>
                </v-radio-group>

                <div class="info-tank">
                    <v-text-field v-model="asset.volume_total" :label="$t('edit_equipment.volume_L')" :rules="require_field" filled />
                    <v-text-field v-model="asset.height" :label="$t('edit_equipment.height_cm')" :rules="require_field" filled />
                    <v-text-field v-model="asset.length" :label="$t('edit_equipment.inside_length_cm')" :rules="require_field" filled />
                    <v-text-field v-model="asset.width" :label="$t('edit_equipment.inside_width_cm')" :rules="require_field" filled />

                    <v-text-field v-model="asset.offset" :label="$t('edit_equipment.offset')" :rules="require_field" filled type="number" />

                    <v-text-field
                        v-model="asset.offset_bottom"
                        :label="$t('edit_equipment.offset_bottom')"
                        :rules="require_field"
                        filled
                        type="number"
                    />
                </div>
            </div>
        </div>

        <div id="equipment_location" class="mt-5">
            <span class="section_title">
                {{ $t('edit_equipment.equipment_location') }}
            </span>

            <v-row>
                <v-col class="pl-3" cols="6">
                    <v-text-field v-model="asset.latitude" :label="$t('edit_equipment.latitude')" cols="6" filled />
                </v-col>
                <v-col class="pl-0" cols="6">
                    <v-text-field v-model="asset.longitude" :label="$t('edit_equipment.longitude')" cols="6" filled />
                </v-col>
            </v-row>

            <v-row class="ma-0">
                <v-btn class="validation-button" color="primary" @click="geolocationAsset()">
                    <v-icon class="mr-1" dense>mdi-crosshairs-gps</v-icon>
                    {{ $t('edit_equipment.geolocate_me') }}
                </v-btn>
            </v-row>

            <v-row v-if="!geolocation_activate">
                <span class="ml-4 error--text font-weight-bold">
                    {{ $t('edit_equipment.check_geolocation_parameter') }}
                </span>

                <v-icon color="error" dense @click="openGeolocationProcedureDialog"> mdi-help-circle</v-icon>
            </v-row>
        </div>

        <div id="equipment_address" class="mt-5">
            <span class="section_title">
                {{ $t('edit_equipment.site_address') }}
            </span>

            <v-radio-group v-model="type_address" class="mt-1" @change="hideMap()">
                <v-radio :label="$t('edit_equipment.use_existing_address')" value="existing_address" />
                <v-radio :label="$t('edit_equipment.use_another_address')" value="other_address" @click="hideMap" />
                <v-radio :label="$t('edit_equipment.geolocate_me')" value="geolocation" @click="geolocation()" />
            </v-radio-group>

            <div v-if="type_address === 'other_address'">
                <p class="font-14 mb-0">
                    {{ $t('edit_equipment.cant_find_address') }}
                </p>
                <p class="font-14 address-link">
                    <a @click="showMap">
                        {{ $t('edit_equipment.place_point_on_map') }}
                    </a>
                </p>
            </div>

            <div id="existing_address">
                <v-alert v-if="show_map" icon="mdi-alert-outline" text type="warning">
                    <div class="alert-text">
                        {{ $t('edit_equipment.geolocation_create_new_domicile') }}
                    </div>
                </v-alert>


                <GmapAutocomplete v-if="type_address === 'other_address' && !show_map" class="w-100" @place_changed="setGoogleAddress" />

                <div v-else>
                    <div v-if="selected_existing_address">
                        <v-select
                            v-model="
                                type_address === 'existing_address' || (type_address === 'other_address' && !show_map)
                                    ? selected_existing_address
                                    : selected_google_address
                            "
                            :item-text="
                                type_address === 'existing_address' || (type_address === 'other_address' && !show_map)
                                    ? 'address'
                                    : 'formatted_address'
                            "
                            :items="
                                type_address === 'existing_address' || (type_address === 'other_address' && !show_map)
                                    ? asset_data.domiciles
                                    : google_address
                            "
                            :label="
                                type_address === 'existing_address' || (type_address === 'other_address' && !show_map)
                                    ? this.$t('edit_equipment.existing_address')
                                    : this.$t('edit_equipment.nearby_addresses')
                            "
                            :loading="loading_address"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :rules="require_field"
                            outlined
                            return-object
                            @change="updateContactInformations"
                        >
                            <template v-slot:no-data>
                                <v-list class="text-center">
                                    {{ $t('edit_equipment.no_address_found') }}
                                </v-list>
                            </template>

                            <template v-slot:item="{ item }" v-slot:selection="{ item }">
                                <span v-if="!show_map">
                                    {{ item.address + ' ' + item.postal_code + ' ' + item.city + ' ' + item.country }}
                                </span>
                                <span v-else>
                                    {{ item.formatted_address }}
                                </span>
                            </template>
                        </v-select>

                        <v-text-field
                            v-model="asset.name_address"
                            :label="$t('edit_equipment.domicile_name')"
                            :rules="require_field"
                            filled
                        />
                    </div>

                    <div v-else-if="!show_map" class="font-14 mb-4">
                        {{ $t('edit_equipment.no_address_associate_to_asset') }}
                    </div>
                </div>
            </div>

            <gmap-map v-if="show_map" ref="gmap" :center="center" :zoom="zoom" class="gmap-map" @click="putMarker">
                <google-map-cluster>
                    <gmap-marker
                        v-if="marker.latitude !== ''"
                        :clickable="true"
                        :draggable="false"
                        :icon="{ url: '/img/icons/marker.png' }"
                        :position="{ lat: marker.latitude, lng: marker.longitude }"
                    />
                </google-map-cluster>
            </gmap-map>
        </div>

        <div v-if="asset.domicile" id="contact_site">
            <span class="section_title">
                {{ $t('edit_equipment.onsite_contact_information') }}
            </span>
            <v-text-field v-model="asset.domicile.contact_last_name" :label="$t('edit_equipment.last_name')" cols="6" filled />
            <v-text-field v-model="asset.domicile.contact_first_name" :label="$t('edit_equipment.first_name')" cols="6" filled />
            <v-text-field
                v-model="asset.domicile.contact_mail"
                :label="$t('edit_equipment.email')"
                :rules="email_rules"
                filled
                prepend-inner-icon="mdi-at"
                type="email"
            />
            <v-text-field
                v-model="asset.domicile.contact_phone"
                :label="$t('edit_equipment.phone')"
                filled
                prepend-inner-icon="mdi-phone"
                type="email"
            />
            <v-textarea v-model="asset.domicile.contact_note" :label="$t('edit_equipment.note_on_contact')" class="mt-1" filled rows="3" />
        </div>

        <manage-note-admin-device-component :device_number="device_number" />

        <v-btn :class="invalid ? '' : 'validation-button'" :disabled="invalid" block class="mt-4" large @click="editEquipment">
            {{ $t('global.update') }}
        </v-btn>

        <add-asset-liquid-dialog-component ref="AddAssetLiquidDialog" v-on:add:liquid="$refs.DeviceInputToReturn.checkDevice()" />
        <geolocation-activation-procedure-dialog-component ref="GeolocationActivationProcedureDialog" />
    </div>
</template>

<script>
import ManageNoteAdminDeviceWidgetComponent from '@/components/ManageNoteAdminDeviceWidgetComponent.vue'
import GoogleMapCluster from 'vue2-google-maps/dist/components/cluster'
import ManageAssetImageComponent from '@/components/ManageAssetImageComponent.vue'
import AssetRepository from '@/repositories/AssetRepository'
import BrandReferenceRepository from '@/repositories/BrandReferenceRepository'
import ModelRepository from '@/repositories/ModelRepository'
import AddAssetLiquidDialogComponent from '@/components/AddAssetLiquidDialogComponent.vue'
import AddressRepository from '@/repositories/AddressRepository'
import GeolocationActivationProcedureDialogComponent from '@/components/GeolocationActivationProcedureDialogComponent.vue'

export default {
    name: 'DefaultEquipmentFormComponent',
    components: {
        GeolocationActivationProcedureDialogComponent,
        AddAssetLiquidDialogComponent,
        ManageAssetImageComponent,
        GoogleMapCluster,
        ManageNoteAdminDeviceWidgetComponent,
    },
    props: {
        device_number: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            asset: {},

            asset_liquids: [],
            loading_content: false,

            know_model: false,

            brand_reference: [],
            selected_brand_reference: '',
            loading_brand: false,

            model_reference: [],
            selected_model_reference: '',
            loading_model: false,

            model_offset: null,
            model_offset_bottom: null,

            type_address: 'existing_address',
            loading_address: false,

            existing_address: [],
            selected_existing_address: '',

            google_address: [],
            selected_google_address: '',

            geolocation_activate: true,
            show_map: false,
            zoom: 4,
            center: {
                lat: 46.804835694647416,
                lng: 2.6708984374999822,
            },
            marker: {
                latitude: '',
                longitude: '',
            },

            require_field: [(v) => !!v || 'Champs requis'],
            properties: ['volume_total', 'height', 'length', 'width'],

            asset_liquid: {
                label: '',
                id_ref_contenant: null,
                color: '#CCCCCC',
                density: 1,
                id_professional: null,
            },

            email_rules: [
                (v) =>
                    v === '' ||
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        v,
                    ) ||
                    'Email non valide',
            ],
            defaultDomicile: null,
        }
    },
    computed: {
        invalid: function () {
            return !(
                this.device_number !== '' &&
                this.asset.name !== '' &&
                this.asset.id_liquid !== '' &&
                ((this.know_model &&
                    this.selected_brand_reference !== '' &&
                    this.selected_model_reference !== '' &&
                    this.model_offset != null &&
                    this.model_offset_bottom != null) ||
                    (!this.know_model &&
                        this.asset.type !== '' &&
                        this.asset.type !== 0 &&
                        this.asset.offset !== '' &&
                        this.asset.offset_bottom !== '' &&
                        this.asset.length !== '' &&
                        this.asset.width !== '' &&
                        this.asset.height !== '' &&
                        this.asset.volume_total !== '')) &&
                (this.selected_google_address !== '' || (this.type_address === 'existing_address' && this.selected_existing_address !== ''))
            )
        },
    },
    watch: {
        'asset.type'() {
            this.refreshPropertyValueIfTankTypeChange()
        },
    },
    methods: {
        openGeolocationProcedureDialog() {
            this.$refs.GeolocationActivationProcedureDialog.openGeolocationActivationProcedureDialog()
        },

        getDeviceAsset(data, value) {
            this.asset_data = data
            this.asset = data.asset
            this.defaultDomicile = { ...data.asset.domicile }
            this.asset_liquids = data.asset_liquids

            this.getBrandReference()
            if (data.asset.brand_reference !== null && data.asset.model_reference !== null) {
                this.know_model = true
                this.selected_brand_reference = data.asset.brand_reference.id_brand_reference
                this.selected_model_reference = data.asset.model_reference
                this.getModelReference()
            }
            this.device_number = value
            this.selected_existing_address = data.asset.domicile
            this.model_offset = data.asset.offset
            this.model_offset_bottom = data.asset.offset_bottom

            this.asset_liquid.id_professional = data.asset.id_professional
            this.asset_liquid.id_ref_contenant = data.asset.id_ref_contenant
            this.asset_liquid.color = '#CCCCCC'
            this.asset_liquid.density = 1
        },

        getValidGoogleAddress() {
            this.google_address = []
            this.loading_address = true
            AddressRepository.getAddressFromGoogleApi(this.marker.latitude, this.marker.longitude)
                .then((success) => {
                    success.data.results.forEach((address) => {
                        let types = []
                        address.address_components.forEach((component) => {
                            component.types.forEach((type) => {
                                types.push(type)
                            })
                        })
                        if (
                            types.includes('street_number') &&
                            types.includes('route') &&
                            types.includes('locality') &&
                            types.includes('postal_code') &&
                            types.includes('country')
                        ) {
                            this.google_address.push(address)
                        }
                    })
                })
                .catch(() => {
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'error',
                        text: this.$t('edit_equipment.unable_retrieve_addresses'),
                    })
                })
                .finally(() => {
                    this.loading_address = false
                })
        },

        openAddAssetLiquidDialog() {
            this.$refs.AddAssetLiquidDialog.openAddAssetLiquidDialog(this.asset_liquid)
        },

        getBrandReference() {
            this.loading_brand = true
            BrandReferenceRepository.geBrandReference()
                .then((success) => {
                    this.brand_reference = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_brand = false
                })
        },

        getModelReference() {
            this.model_reference = []
            this.loading_model = true
            ModelRepository.getModelReference(this.selected_brand_reference)
                .then((success) => {
                    this.model_reference = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_model = false
                })
        },

        setGoogleAddress(address) {
            let count = 0
            address.address_components.forEach((component) => {
                if (component.types.includes('street_number')) {
                    count++
                } else if (component.types.includes('route')) {
                    count++
                } else if (component.types.includes('locality')) {
                    count++
                } else if (component.types.includes('country')) {
                    count++
                } else if (component.types.includes('postal_code')) {
                    count++
                }
            })
            if (count >= 5) {
                this.selected_google_address = address
            } else {
                this.$store.commit('snackbar/showSnackbar', {
                    color: 'error',
                    text: this.$t('edit_equipment.selected_address_invalid'),
                })
            }
        },

        putMarker(event) {
            this.marker.latitude = event.latLng.lat()
            this.marker.longitude = event.latLng.lng()
            this.getValidGoogleAddress()
        },

        geolocation() {
            this.asset.name_address = null

            this.zoom = 18
            this.show_map = true
            navigator.geolocation.getCurrentPosition((pos) => {
                this.marker.latitude = pos.coords.latitude
                this.marker.longitude = pos.coords.longitude
                this.center.lat = pos.coords.latitude
                this.center.lng = pos.coords.longitude
                this.getValidGoogleAddress()
            })
        },

        geolocationAsset() {
            navigator.geolocation.getCurrentPosition(
                (pos) => {
                    this.geolocation_activate = true
                    this.asset.latitude = pos.coords.latitude
                    this.asset.longitude = pos.coords.longitude
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        this.geolocation_activate = false
                    }
                },
            )
        },

        showMap() {
            this.show_map = true
            this.zoom = 5
            this.center = {
                lat: 46.804835694647416,
                lng: 2.6708984374999822,
            }
        },

        hideMap() {
            if (this.type_address === 'existing_address') {
                this.updateContactInformations({ ...this.defaultDomicile })
            } else {
                this.updateContactInformations(null)
            }
            this.selected_google_address = ''
            this.show_map = false
            this.google_address = []
            this.marker = {
                latitude: '',
                longitude: '',
            }
        },

        editEquipment() {
            this.$store.commit('loader/showLoader', this.$t('edit_equipment.equipment_update'))

            let asset = {}
            if (this.know_model) {
                asset = {
                    know_model_brand_asset: true,
                    asset_name: this.asset.name,
                    id_brand_reference: this.selected_brand_reference,
                    id_model_reference: this.selected_model_reference.id_model_reference,
                    id_liquid: this.asset.id_liquid,
                    offset: this.model_offset,
                    offset_bottom: this.model_offset_bottom,
                    is_mobile: false,
                    index_monitoring_type: '-1',
                    latitude: this.asset.latitude,
                    longitude: this.asset.longitude,
                }
            } else {
                asset = {
                    height: this.asset.height,
                    asset_name: this.asset.name,
                    volume: this.asset.volume_total,
                    offset: this.asset.offset,
                    offset_bottom: this.asset.offset_bottom,
                    width: this.asset.width,
                    length: this.asset.length,
                    tank_type: this.asset.type,
                    is_mobile: false,
                    index_monitoring_type: '-1',
                    id_liquid: this.asset.id_liquid,
                    know_model_brand_asset: false,
                    latitude: this.asset.latitude,
                    longitude: this.asset.longitude,
                }
            }

            let domicile = {
                route: '', // rue des Godrans
                street_number: '', // 67
                name: '', // rue des Godrans
                locality: '', // Dijon
                country: '', // France
                country_code: '', // FR
                latitude: '', // 47.3223023
                longitude: '', // 5.0381338
                postal_code: null, // 2100
                address2: '',
                contact_first_name: '',
                contact_last_name: '',
                contact_mail: '',
                contact_phone: '',
                contact_note: '',
            }

            if (this.type_address !== 'existing_address') {
                domicile.address2 = !this.selected_google_address.formatted_address.includes(this.selected_google_address.name)
                    ? this.selected_google_address.name
                    : ''
                domicile.contact_first_name = this.asset.domicile.contact_first_name
                domicile.contact_last_name = this.asset.domicile.contact_last_name
                domicile.contact_mail = this.asset.domicile.contact_mail
                domicile.contact_phone = this.asset.domicile.contact_phone
                domicile.contact_note = this.asset.domicile.contact_note
                let components = this.selected_google_address.address_components
                components.forEach((component) => {
                    if (component.types.includes('street_number')) {
                        domicile.street_number = component.long_name
                    } else if (component.types.includes('route')) {
                        domicile.route = component.long_name
                    } else if (component.types.includes('locality')) {
                        domicile.locality = component.long_name
                    } else if (component.types.includes('country')) {
                        domicile.country = component.long_name
                        domicile.country_code = component.short_name
                    } else if (component.types.includes('postal_code')) {
                        domicile.postal_code = component.long_name
                    }
                })

                if (this.show_map) {
                    domicile.latitude = this.selected_google_address.geometry.location.lat
                    domicile.longitude = this.selected_google_address.geometry.location.lat
                } else {
                    domicile.latitude = this.selected_google_address.geometry.location.lat()
                    domicile.longitude = this.selected_google_address.geometry.location.lng()
                }
            } else if (this.selected_existing_address) {
                domicile = {
                    id_domicile: this.selected_existing_address.id_domicile,
                    contact_first_name: this.asset.domicile.contact_first_name,
                    contact_last_name: this.asset.domicile.contact_last_name,
                    contact_mail: this.asset.domicile.contact_mail,
                    contact_phone: this.asset.domicile.contact_phone,
                    contact_note: this.asset.domicile.contact_note,
                }
            } else {
                domicile = null
            }

            AssetRepository.putEditAsset(this.asset.id_asset, asset, domicile)
                .then(() => {
                    this.$store.commit('successAlert/showSuccessAlert', true)
                    this.$store.commit('successAlert/addMessageToSuccessAlert', {
                        value_top: this.$t('edit_equipment.equipment_associate_with_device_updated', {
                            asset: this.asset.name,
                            device: this.device_number,
                        }),
                        value_bottom: '',
                        type: 'success',
                    })
                    this.$store.commit('successAlert/setListeningDevice', this.device_number)
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.$store.commit('loader/hideLoader')
                })
        },

        getTotalPropertySet() {
            let totalPropertySet = 0
            this.properties.forEach((property_name) => {
                if (this.asset[property_name] !== '' && this.asset[property_name] !== null) {
                    totalPropertySet++
                }
            })

            return totalPropertySet
        },

        getPropertyNotSet() {
            let propertyNotSet = ''
            this.properties.forEach((property) => {
                if (this.asset[property] === '' || this.asset[property] === null) {
                    propertyNotSet = property
                }
            })

            return propertyNotSet
        },

        prepareCalculationAutocompletionTank(property_name) {
            if (this.asset.type !== null) {
                switch (this.asset.type) {
                    case 1: {
                        this.calculateRectangularType(property_name)
                        break
                    }
                    case 2: {
                        this.calculateCylindricalHorizontalType(property_name)
                        break
                    }
                    case 3: {
                        this.calculateCylindricalVerticalType(property_name)
                        break
                    }
                    case 5: {
                        this.calculateIbcType(property_name)
                        break
                    }
                }
            }
        },

        calculateRectangularType(property_name) {
            if (this.getTotalPropertySet() === 3) {
                if (this.asset.volume_total === null && this.asset.volume_total === '') {
                    this.asset.volume_total = Math.floor((this.asset.width * this.asset.height * this.asset.length) / 1000)
                } else {
                    let propertyValue = 1
                    this.properties.forEach((property) => {
                        if (this.asset[property] !== '' && this.asset[property] !== null && property !== 'volume_total') {
                            propertyValue = propertyValue * this.asset[property]
                        }
                    })
                    this.asset[this.getPropertyNotSet()] = Math.floor((this.asset.volume_total * 1000) / propertyValue)
                }
            }

            if (this.getTotalPropertySet() === 4) {
                if (property_name === 'width') {
                    this.asset.volume_total = Math.floor((this.asset.width * this.asset.height * this.asset.length) / 1000)
                } else {
                    this.asset.width = Math.floor((this.asset.volume_total * 1000) / (this.asset.length * this.asset.height))
                }
            }
        },

        calculateCylindricalHorizontalType(property_name) {
            if (property_name === 'height' && this.asset.height) {
                this.asset.width = this.asset.height
            } else if (property_name === 'width' && this.asset.width) {
                this.asset.height = this.asset.width
            }

            if (this.asset.volume_total && this.asset.height && this.asset.width) {
                switch (property_name) {
                    case 'length':
                        this.asset.height = this.calculateByVolumeAndX(this.asset.volume_total, this.asset.length)
                        this.asset.width = this.asset.height
                        break
                    case 'volume_total':
                    case 'height':
                        this.asset.length = this.calculateByVolumeAndY(this.asset.volume_total, this.asset.height)
                        break
                }
            }

            if (this.asset.volume_total && this.asset.height && !this.asset.length) {
                this.asset.length = this.calculateByVolumeAndY(this.asset.volume_total, this.asset.height)
            } else if (!this.asset.volume_total && this.asset.height && this.asset.length) {
                this.asset.volume_total = this.calculateByWidthAndLength(this.asset.height, this.asset.length)
            } else if (this.asset.volume_total && this.asset.length && this.asset.height) {
                this.asset.height = this.calculateByVolumeAndX(this.asset.volume_total, this.asset.length)
            }
        },

        calculateCylindricalVerticalType(property_name) {
            if (property_name === 'width' && this.asset.width) {
                this.asset.length = this.asset.width
            } else if (property_name === 'length' && this.asset.length) {
                this.asset.width = this.asset.length
            }

            if (this.asset.volume_total && this.asset.height && this.asset.width) {
                switch (property_name) {
                    case 'width':
                        this.asset.height = this.calculateByVolumeAndY(this.asset.volume_total, this.asset.width)
                        break
                    case 'volume_total':
                    case 'length':
                    case 'height':
                        this.asset.width = this.calculateByVolumeAndX(this.asset.volume_total, this.asset.height)
                        this.asset.length = this.asset.width
                        break
                }
            }

            if (this.asset.volume_total && this.asset.length && !this.asset.width) {
                this.asset.width = this.calculateByVolumeAndX(this.asset.volume_total, this.asset.height)
                this.asset.length = this.asset.width
            } else if (!this.asset.volume_total && this.asset.height && this.asset.width) {
                this.asset.volume_total = this.calculateByWidthAndLength(this.asset.width, this.asset.height)
            } else if (this.asset.volume_total && this.asset.width && !this.asset.length) {
                this.asset.height = this.calculateByVolumeAndY(this.asset.volume_total, this.asset.width)
            }
        },

        calculateByVolumeAndY(x, y) {
            return Math.floor((x * 1000) / (Math.PI * ((y / 2) * (y / 2))))
        },

        calculateByVolumeAndX(x, y) {
            return Math.floor(2 * Math.sqrt((x * 1000) / (Math.PI * y)))
        },

        calculateByWidthAndLength(x, y) {
            return Math.floor((Math.PI * (x / 2) * (x / 2) * y) / 1000)
        },

        calculateIbcType(property_name) {
            if (this.getTotalPropertySet() >= 2) {
                if (property_name === 'volume_total') {
                    this.setPropertyValueTank(Math.round((Math.round(Math.pow(this.asset.volume_total, 1 / 3) * 100) / 100) * 10))
                } else {
                    this.asset.volume_total = Math.round(Math.pow(this.asset[property_name], 3) / 1000)
                    this.setPropertyValueTank(this.asset[property_name])
                }
            }
        },

        setPropertyValueTank(property_value) {
            this.asset.height = property_value
            this.asset.length = property_value
            this.asset.width = property_value
        },

        refreshPropertyValueIfTankTypeChange() {
            if (this.getTotalPropertySet() >= 2) {
                this.prepareCalculationAutocompletionTank('volume_total')
            }
        },

        updateContactInformations(domicile) {
            if (domicile) {
                this.asset.domicile.contact_last_name = domicile.contact_last_name
                this.asset.domicile.contact_first_name = domicile.contact_first_name
                this.asset.domicile.contact_phone = domicile.contact_phone
                this.asset.domicile.contact_mail = domicile.contact_mail
                this.asset.domicile.contact_note = domicile.contact_note
            } else {
                this.asset.domicile.contact_last_name = ''
                this.asset.domicile.contact_first_name = ''
                this.asset.domicile.contact_phone = ''
                this.asset.domicile.contact_mail = ''
                this.asset.domicile.contact_note = ''
            }
        },
    },
}
</script>

<style>
#equipment_details .v-input__slot {
    background-color: white !important;
    border: white !important;
}

.section_title {
    font-size: 14px;
    color: #4c545f;
    font-weight: bold;
}

.img-text {
    font-size: 12px;
    color: #4c545f;
    line-height: 14px;
    margin-bottom: 0 !important;
}

.know_model .v-input__slot {
    background-color: white !important;
    border: white !important;
}

#existing_address .v-input__slot {
    background-color: white !important;
    border: white !important;
}

.address-link {
    text-decoration: underline;
}

.gmap-map {
    width: 100%;
    height: 300px;
}

.info-tank .v-input__slot,
#equipment_location .v-input__slot,
#contact_site .v-input__slot {
    background-color: white !important;
    border: white !important;
    background-repeat: repeat !important;
}

.radio-tank .v-input--selection-controls__input {
    margin-right: 0 !important;
}

.pac-target-input {
    background-color: white;
    padding: 15px;
    margin-bottom: 25px;
}

.border-import-file {
    border-style: dashed;
    border-width: 2px;
}
</style>
