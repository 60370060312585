import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"manage-note-admin-device-widget-component"}},[_c('widget-wrapper-component',{attrs:{"title":_vm.$t('edit_equipment.device_information', { device: _vm.device_number }),"icon":"mdi-remote"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{on:{"click":function($event){_vm.temporary_note_admin = _vm.note_admin
                    _vm.dialog_update_note_admin_device = true}}},[_c(VTextarea,{staticClass:"mt-1",attrs:{"label":_vm.$t('edit_equipment.device_admin_note', { device: _vm.device_number }),"filled":"","hide-details":"","readonly":"","rows":"3"},model:{value:(_vm.note_admin),callback:function ($$v) {_vm.note_admin=$$v},expression:"note_admin"}})],1)]},proxy:true}])}),_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.dialog_update_note_admin_device),callback:function ($$v) {_vm.dialog_update_note_admin_device=$$v},expression:"dialog_update_note_admin_device"}},[_c(VCard,[_c(VCardTitle,{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t('edit_equipment.edit_device_admin_note', { device: _vm.device_number }))+" ")]),_c(VCardText,{staticClass:"mt-4 pb-0"},[_c(VTextarea,{attrs:{"label":_vm.$t('edit_equipment.device_admin_note', { device: _vm.device_number }),"filled":"","rows":"3"},model:{value:(_vm.temporary_note_admin),callback:function ($$v) {_vm.temporary_note_admin=$$v},expression:"temporary_note_admin"}})],1),_c(VContainer,{staticClass:"px-6"},[_c(VBtn,{staticClass:"validation-button",attrs:{"loading":_vm.loading_update_note_admin_device,"block":"","color":"primary","large":""},on:{"click":function($event){$event.preventDefault();return _vm.updateDeviceNote()}}},[_vm._v(" "+_vm._s(_vm.$t('global.confirm'))+" ")]),_c(VBtn,{staticClass:"cancel-btn mt-1",attrs:{"block":"","large":"","text":""},on:{"click":function($event){_vm.dialog_update_note_admin_device = false}}},[_vm._v(" "+_vm._s(_vm.$t('global.cancel'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }