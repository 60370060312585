import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"edit-asset-of-asset-module-form-component"}},[(!_vm.fetchingLiquids)?[_c('div',[_c('div',{staticClass:"d-flex flex-column gap-4"},[_c('asset-summary-component',{attrs:{"asset":_vm.asset,"module":_vm.module}}),_c('manage-asset-image-component',{attrs:{"asset":_vm.asset}}),_c('manage-note-admin-device-widget-component',{attrs:{"device_number":_vm.device_number}}),_c('div',{attrs:{"id":"edit-asset-of-asset-module-form"}},[_c('widget-wrapper-component',{attrs:{"title":_vm.$t('edit_asset_of_asset_module_form_component.asset'),"is-already-open":""},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('custom-icon-component',{attrs:{"icon":_vm.module.menu_icon,"default-icon":"mdi-remote"}})]},proxy:true},{key:"default",fn:function(){
var this$1 = this;
return [_c(VForm,{ref:"form",staticClass:"mb-4"},[_c(VTextField,{attrs:{"label":_vm.$t('edit_asset_of_asset_module_form_component.ref_equipment'),"filled":""},model:{value:(_vm.asset.code_asset),callback:function ($$v) {_vm.$set(_vm.asset, "code_asset", $$v)},expression:"asset.code_asset"}}),_c(VTextField,{attrs:{"label":_vm.$t('edit_asset_of_asset_module_form_component.name_equipment'),"rules":[function (v) { return !!v || this$1.$t('generic_form_component.required_fields'); }],"filled":""},model:{value:(_vm.asset.name),callback:function ($$v) {_vm.$set(_vm.asset, "name", $$v)},expression:"asset.name"}}),_c(VRow,{staticClass:"mb-2"},_vm._l((_vm.module.total_models),function(index){return _c(VCol,{attrs:{"lg":_vm.module.total_models === 1 ? '12' : '3',"md":_vm.module.total_models === 1 ? '12' : '6',"cols":"12"}},[_c('p',{staticClass:"mb-0 font-weight-medium text-uppercase"},[_vm._v(_vm._s(_vm.module.name)+" "+_vm._s(index))]),_c('AssetModelFormWrapperComponent',{attrs:{"asset_liquids":_vm.asset_liquids,"asset_module":_vm.module,"column":index - 1,"configuration":_vm.configuration[index - 1],"authorize_config_selection":!_vm.assetHasOneModelOnly},on:{"update:model":_vm.handleModelConfigurationUpdate}})],1)}),1),_c(VBtn,{staticClass:"validation-button",attrs:{"loading":_vm.editing_asset,"block":"","large":""},on:{"click":_vm.prepareUpdate}},[_vm._v(" "+_vm._s(_vm.$t('global.validate'))+" ")])],1)]},proxy:true}],null,false,770585061)})],1),_c('associate-client-widget-component',{attrs:{"asset":_vm.asset},on:{"refresh:form":function($event){return _vm.$emit('refresh:form')}}}),_c('associate-site-to-asset-widget-component',{attrs:{"asset":_vm.asset},on:{"refresh:form":function($event){return _vm.$emit('refresh:form')}}}),_c('localisation-asset-widget-component',{attrs:{"asset":_vm.asset},on:{"refresh:form":function($event){return _vm.$emit('refresh:form')}}})],1)])]:[_c(VSkeletonLoader,{attrs:{"type":"image"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }