import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"associate-client-to-asset-dialog-component"}},[_c(VDialog,{attrs:{"width":"600"},model:{value:(_vm.is_associate_client_to_asset_dialog_open),callback:function ($$v) {_vm.is_associate_client_to_asset_dialog_open=$$v},expression:"is_associate_client_to_asset_dialog_open"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t('associate_client_to_asset_dialog_component.associate_client')))]),_c(VCardText,[_c(VForm,{ref:"form"},[_c(VSelect,{attrs:{"items":_vm.clients,"label":_vm.$t('associate_client_to_asset_dialog_component.client'),"loading":_vm.fetching_client,"menu-props":{ bottom: true, offsetY: true },"no-data-text":_vm.$t('associate_client_to_asset_dialog_component.no_client_available'),"rules":[function (v) { return !!v.id_client || this$1.$t('generic_form_component.required_fields'); }],"filled":"","item-text":"client_space_name","item-value":"id_client","return-object":""},on:{"change":_vm.fetchClientDomiciles},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c(VBtn,{attrs:{"block":"","text":""},on:{"click":_vm.openAddClientDialog}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('global.add'))+" ")],1)]},proxy:true}]),model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}}),_c(VSelect,{attrs:{"item-text":function (item) {
                                return _vm.displayHumanSiteAddress(item)
                            },"items":_vm.domiciles,"label":_vm.$t('exchange_asset_domicile_dialog_component.client_site'),"loading":_vm.fetching_domiciles,"menu-props":{ bottom: true, offsetY: true },"no-data-text":_vm.$t('exchange_asset_domicile_dialog_component.no_client_site'),"rules":[function (v) { return !!v.id_domicile || this$1.$t('generic_form_component.required_fields'); }],"filled":"","item-value":"id_domicile","return-object":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c(VBtn,{attrs:{"block":"","text":""},on:{"click":_vm.openAddClientDomicileDialog}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('global.add'))+" ")],1)]},proxy:true}]),model:{value:(_vm.domicile),callback:function ($$v) {_vm.domicile=$$v},expression:"domicile"}}),_c(VBtn,{staticClass:"validation-button",attrs:{"loading":_vm.associating_client_to_asset,"block":"","large":""},on:{"click":function($event){$event.preventDefault();return _vm.associateAssetToClient()}}},[_vm._v(" "+_vm._s(_vm.$t('global.confirm'))+" ")]),_c(VBtn,{staticClass:"cancel-btn mt-1",attrs:{"block":"","large":"","text":""},on:{"click":function($event){_vm.is_associate_client_to_asset_dialog_open = false}}},[_vm._v(" "+_vm._s(_vm.$t('global.cancel'))+" ")])],1)],1)],1)],1),_c('add-edit-client-dialog-component',{ref:"addEditClientDialog",on:{"added:client":function($event){return _vm.fetchMinimalProfessionalClients()}}}),_c('add-edit-client-domicile-dialog-component',{ref:"addEditClientDomicileDialog",on:{"added:domicile":_vm.fetchClientDomiciles}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }